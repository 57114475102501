import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'

const ProductDesignPersono = props => (
  <Layout>
    <Helmet>
      <title>Persono</title>
      <meta
        name="Design of a coffee table for the living room"
        content="Product Page"
      />
    </Helmet>

    <div id="main" className="alt">
      <section id="work-banner">
        <div>
          <span className="image main">
            <Img fluid={props.data.coverimage.childImageSharp.fluid} alt="" />
          </span>
        </div>
      </section>

      <div className="project-wrapper">
        <div className="project-content">
          <header>
            <span className="tag">PRODUCT DESIGN</span>
            <span className="tag">USER EXPERIENCE</span>
            <h1>persono.</h1>
            <h3>
              A coffee table that allows the ultimate flexibility of usage.
              Share it easily among few people or use it for your own private &
              personal use.
            </h3>
          </header>
          <section className="challenge-section">
            <span className="project-step-number">01</span>
            <h2>challenge</h2>
            <div className="challenge">
              <div className="challenge-desc">
                <p>
                  Design a coffee table to be used at home for different usage
                  scenarios. A table can be easily shared by a group of people
                  doing the same thing, but it gets difficult to share a table
                  in situations when multiple people want to use it for their
                  own personal & private needs at the same time.
                </p>
              </div>
              <div className="challenge-credits">
                <div className="topbar" />
                <span className="credits-text">
                  <strong>YEAR:</strong> 2014
                </span>
                <span className="credits-text">
                  <strong>DURATION:</strong> 4-5 months
                </span>
                <span className="credits-text">
                  <strong>PROJECT TYPE:</strong> Household furniture design
                </span>
                <span className="credits-text">
                  <strong>TEAM MEMBERS:</strong> Individual project
                </span>
                <span className="credits-text">
                  <strong>MY ROLE:</strong> User behaviour observation &
                  interviews, conceptualisation, sketching of ideas, 3D
                  modelling & technical drafting, final 3D rendering of the
                  product, assisting in the manufacturing process of the first
                  prototype.
                </span>
              </div>
            </div>
            <div className="image">
              <Img
                fluid={props.data.persono_challenge1.childImageSharp.fluid}
                alt="Persono Challenge Research"
                title="Persono Research"
              />
            </div>
          </section>
          <section className="process-section">
            <span className="project-step-number">02</span>
            <h2>process</h2>
            <p className="full-text">
              Different use-cases of people using a coffee table in a home
              setting were observed. Based on the user insight, several concepts
              were drawn up and a final idea was consolidated. The technical
              workings of the table was solved by developing of a custom-made
              magnetic pivot mechanism. The 1:1 working prototype of the table
              was made using flexible plywood.
            </p>

            <div className="two-sidebyside">
              <div className="img left">
                <Img
                  className="project-process-images"
                  fluid={props.data.persono_process1.childImageSharp.fluid}
                  alt="Persono Concept"
                  title="Persono Concept"
                />
              </div>
              <div className="img right">
                <Img
                  className="project-process-images"
                  fluid={props.data.persono_process2.childImageSharp.fluid}
                  alt="Persono Major Feature"
                  title="Persono Major Feature"
                />
              </div>
            </div>
            <div className="image">
              <Img fluid={props.data.persono_process3.childImageSharp.fluid} />
            </div>
          </section>
          <section className="outcome-section">
            <div className="outcome-text-wrapper">
              <span className="project-step-number">03</span>
              <h2>outcome</h2>
              <h3>
                A flexible coffee table that can be expanded so that it is
                accessible to a small or large group of people. The
                magnetic-pivot mechanism of the table means it can be split up
                into its individual sections for a more personal & private use.
                The modular nature of the table allows it to be easily
                manufactured and transported. Overall, the design is
                contemporary, multi-functional, personal & playful.
              </h3>
            </div>
            <Img fluid={props.data.persono_outcome1.childImageSharp.fluid} />
          </section>

          <section id="footertopcta" className="inner">
            <p className="text arrow-back">
              head back to all
              <br />
              <a href="/work"> my work</a>
            </p>
          </section>
        </div>
      </div>
    </div>
    <div className="footer-top">
      <div className="inner">
        <h2>other projects.</h2>
        <div id="work">
          <div className="card-wrapper">
            <a href="/work/product-design-household-dustbins">
              <div className="image">
                <Img fluid={props.data.project1.childImageSharp.fluid} alt="" />
              </div>
              <div className="card-info">
                <div className="title">blinky, clyde, inky, pinky.</div>
                <div className="text">
                  A family of household dustbins whose aesthetic appearance can
                  be customised to user's preferences.
                </div>
                <div className="tags">
                  <ul>
                    <li>PRODUCT DESIGN</li>
                    <li>USER EXPERIENCE</li>
                  </ul>
                </div>
              </div>
            </a>
          </div>
          <div className="card-wrapper">
            <a href="/work/service-design-muvisi-private">
              <div className="image">
                <Img fluid={props.data.project2.childImageSharp.fluid} alt="" />
              </div>
              <div className="card-info">
                <div className="title">muvisi.</div>
                <div className="text">
                  A mobility service that makes it stress-free & convenient to
                  find parking spots in the city.
                </div>
                <div className="tags">
                  <ul>
                    <li>SERVICE DESIGN</li>
                    <li>USER EXPERIENCE</li>
                    <li>DIGITAL UX/UI</li>
                    <li>INTERACTION DESIGN</li>
                    <li>BRANDING</li>
                  </ul>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ProductDesignPersono

export const fluidPersonoImage = graphql`
  fragment fluidPersonoImage on File {
    childImageSharp {
      fluid(maxWidth: 920, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    coverimage: file(
      relativePath: {
        eq: "projects/product/persono/cover/persono-cover-image.jpg"
      }
    ) {
      ...fluidPersonoImage
    }
    persono_challenge1: file(
      relativePath: {
        eq: "projects/product/persono/challenge/01_persono-research.jpg"
      }
    ) {
      ...fluidPersonoImage
    }
    persono_process1: file(
      relativePath: {
        eq: "projects/product/persono/process/02_persono-sketches.jpg"
      }
    ) {
      ...fluidPersonoImage
    }
    persono_process2: file(
      relativePath: {
        eq: "projects/product/persono/process/03_persono-features.jpg"
      }
    ) {
      ...fluidPersonoImage
    }
    persono_process3: file(
      relativePath: {
        eq: "projects/product/persono/process/05_persono-manufacturing.jpg"
      }
    ) {
      ...fluidPersonoImage
    }
    persono_outcome1: file(
      relativePath: {
        eq: "projects/product/persono/outcome/06_persono-prototype.jpg"
      }
    ) {
      ...fluidPersonoImage
    }
    project1: file(relativePath: { eq: "projects/2.png" }) {
      ...fluidPersonoImage
    }
    project2: file(relativePath: { eq: "projects/19.png" }) {
      ...fluidPersonoImage
    }
  }
`
